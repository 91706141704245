@import "../../../styles/variables";
@import "../../../styles/colors";
@import "../../../styles/mixins";

$PROFILE_BG: "../../../styles/assets/images/sbux/profile-bg.png";

.profile-dialog {
  background-color: $green800;
  height: 100vh;
  min-height: 100vh;

  .dialog-header {
    width: 100%;

    .close-icon {
      z-index: 3;
      position: absolute;
      right: calc($grid-1x/2);
      top: calc($grid-1x/2);
      cursor: pointer;
    }
  }

  .dialog-content {
    background-color: $green800;
    padding: 0 calc($grid-2x + 4px) 0 calc($grid-2x + 4px);
    width: 100%;
    min-height: 100%;
    max-width: calc(7 * $grid-10x);
    margin: 0 auto;

    .profile-icon {
      margin-left: $grid-1x;
      padding: calc($grid-3x - 2px);
      background-color: $secondary;
      color: $secondary-text;

      .user-icon {
        font-size: 4.5rem;
      }
    }

    .profile-card {
      padding: calc($grid-2x - 4px) $grid-2x;
      @include roundCorner($grid-2x);
      @include boxShadow(0 calc($grid-1x/2) $grid-1x rgba($black, 0.1));
      margin-top: $grid-2x;

      .card-header {
        padding: $grid-1x 0 $grid-1x;

        & span {
          font-size: 1.143rem;
          color: $primary-text;
          font-family: $SodoSansSemiBold;
        }
      }

      .card-content {
        padding: calc($grid-1x/2) 0;
        color: $secondary;

        h1 {
          color: $primary-text;
          font-family: $SodoSansSemiBold;
        }
      }

      .card-content:focus-visible {
        outline: none;
      }

      .language-content {
        padding: 0 $grid-1x;

        label {
          color: $primary-text;

          span.Mui-checked {
            color: $secondary;
          }
        }
      }

      .language-content:focus-visible {
        outline: none;
      }

      .MuiButtonBase-root-MuiRadio-root:focus-visible {
        outline: 2px solid #00754a;
      }
    }

    .logout-btn {
      font-size: 1.143rem;
      padding: $grid-1x 0;
      margin: $grid-2x 0 $grid-1x;
      width: 100%;
    }

    .profile-bg {
      background-image: url($PROFILE_BG);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: calc(3 * $grid-10x) auto;
      height: calc(2 * $grid-9x);
      width: 100%;
      opacity: 0.4;
    }
  }
}

.language-dialog.confirm-dialog {
  .MuiDialog-paper {
    min-width: calc(4 * $grid-7x);

    .MuiDialogContent-root {
      padding-bottom: 0;

      .dialog-content {
        h2 {
          font-family: $SodoSans;
        }
      }
    }

    .MuiDialogActions-root {
      button {
        margin: calc($grid-1x + 2px) 0 calc($grid-1x);
        padding: calc($grid-1x + 2px) $grid-1x calc($grid-1x + 2px) $grid-1x;
      }
    }
  }
}

@media only screen and (min-width: $breakpoint-sm-desktop) {
  .profile-dialog {
    &::before {
      background-image: url($PROFILE_BG);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 100%;
      width: 100%;
      opacity: 0.2;
      content: "";
      position: absolute;
      z-index: -1;
    }

    &::after {
      content: "";
      top: 0;
      left: 0;
      right: 0;
      position: fixed;
      height: calc($grid-8x + 1px);
      background-color: transparent;
    }

    .profile-bg {
      display: none;
    }
  }

  .language-dialog.confirm-dialog {
    .MuiDialog-paper {
      .MuiDialogActions-root {
        padding: $grid-1x $grid-2x;

        button {
          margin: $grid-1x 0;
        }
      }
    }
  }
}

@media only screen and (min-width: $breakpoint-normal-desktop) {
  .profile-dialog {
    .dialog-content {
      .profile-card {
        .card-header {
          & span {
            font-size: 1.333rem;
          }
        }
      }
    }
  }
}
