/*--------| HTML, Body |---------*/
html {
  font-size: 14px;
}

/*-------| Main |--------*/
main {
  padding: calc($grid-2x + 4px) 0 0;
}

.parallel-grid {
  & > div.first-grid {
    padding: 0 calc($grid-3x + 4px) $grid-1x;
  }

  & > div.last-grid {
    padding: 0 calc($grid-3x + 4px) $grid-8x;
  }
}

/*-------| User Tabs |--------*/
.MuiTabs-root {
  margin-top: 0.5rem;

  &.user-tabs {
    padding: 0 calc($grid-3x + 4px) calc($grid-2x - 2px);
  }
}

/*-------| Accordion |--------*/
.acc-wrapper {
  .acc-header {
    padding: 0 $grid-2x;
  }

  .acc-content {
    padding: $grid-1x $grid-2x;

    .avail-container {
      padding: $grid-2x $grid-2x $grid-1x;
    }
  }

  &.acc-requests {
    .label-wrapper {
      .label {
        .MuiTypography-body1 {
          font-size: 0.86rem;
        }
      }
    }
  }
}

.vertical-dotted-lines {
  margin-top: calc($grid-1x - 22px);
  width: 91%;
  margin-left: 4%;
}
