@import "../../../styles/colors";

.loader {
  &.fullScreen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(210, 235, 224, 0.5);
    z-index: 9999;
  }

  display: flex;
  justify-content: center;
  align-items: center;

  span.MuiCircularProgress-root {
    color: $secondary;
  }
}
