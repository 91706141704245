@font-face {
  font-family: "SoDo Sans Thin";
  src: url("./assets/fonts/SoDoSans-Thin.eot");
  src:
    local("SoDo Sans Thin"),
    local("SoDoSans-Thin"),
    url("./assets/fonts/SoDoSans-Thin.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/SoDoSans-Thin.woff2") format("woff2"),
    url("./assets/fonts/SoDoSans-Thin.woff") format("woff"),
    url("./assets/fonts/SoDoSans-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SoDo Sans Thin Italic";
  src: url("./assets/fonts/SoDoSans-ThinItalic.eot");
  src:
    local("SoDo Sans Thin Italic"),
    local("SoDoSans-ThinItalic"),
    url("./assets/fonts/SoDoSans-ThinItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/SoDoSans-ThinItalic.woff2") format("woff2"),
    url("./assets/fonts/SoDoSans-ThinItalic.woff") format("woff"),
    url("./assets/fonts/SoDoSans-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "SoDo Sans Light";
  src: url("./assets/fonts/SoDoSans-Light.eot");
  src:
    local("SoDo Sans Light"),
    local("SoDoSans-Light"),
    url("./assets/fonts/SoDoSans-Light.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/SoDoSans-Light.woff2") format("woff2"),
    url("./assets/fonts/SoDoSans-Light.woff") format("woff"),
    url("./assets/fonts/SoDoSans-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SoDo Sans Light Italic";
  src: url("./assets/fonts/SoDoSans-LightItalic.eot");
  src:
    local("SoDo Sans Light Italic"),
    local("SoDoSans-LightItalic"),
    url("./assets/fonts/SoDoSans-LightItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/SoDoSans-LightItalic.woff2") format("woff2"),
    url("./assets/fonts/SoDoSans-LightItalic.woff") format("woff"),
    url("./assets/fonts/SoDoSans-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "SoDo Sans";
  src: url("./assets/fonts/SoDoSans-Regular.eot");
  src:
    local("SoDo Sans Regular"),
    local("SoDoSans-Regular"),
    url("./assets/fonts/SoDoSans-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/SoDoSans-Regular.woff2") format("woff2"),
    url("./assets/fonts/SoDoSans-Regular.woff") format("woff"),
    url("./assets/fonts/SoDoSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SoDo Sans Italic";
  src: url("./assets/fonts/SoDoSans-Italic.eot");
  src:
    local("SoDo Sans Italic"),
    local("SoDoSans-Italic"),
    url("./assets/fonts/SoDoSans-Italic.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/SoDoSans-Italic.woff2") format("woff2"),
    url("./assets/fonts/SoDoSans-Italic.woff") format("woff"),
    url("./assets/fonts/SoDoSans-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "SoDo Sans Semi Bold";
  src: url("./assets/fonts/SoDoSans-SemiBold.eot");
  src:
    local("SoDo Sans SemiBold"),
    local("SoDoSans-SemiBold"),
    url("./assets/fonts/SoDoSans-SemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/SoDoSans-SemiBold.woff2") format("woff2"),
    url("./assets/fonts/SoDoSans-SemiBold.woff") format("woff"),
    url("./assets/fonts/SoDoSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SoDo Sans Semi Bold Italic";
  src: url("./assets/fonts/SoDoSans-SemiBoldItalic.eot");
  src:
    local("SoDo Sans SemiBold Italic"),
    local("SoDoSans-SemiBoldItalic"),
    url("./assets/fonts/SoDoSans-SemiBoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/SoDoSans-SemiBoldItalic.woff2") format("woff2"),
    url("./assets/fonts/SoDoSans-SemiBoldItalic.woff") format("woff"),
    url("./assets/fonts/SoDoSans-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "SoDo Sans Bold";
  src: url("./assets/fonts/SoDoSans-Bold.eot");
  src:
    local("SoDo Sans Bold"),
    local("SoDoSans-Bold"),
    url("./assets/fonts/SoDoSans-Bold.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/SoDoSans-Bold.woff2") format("woff2"),
    url("./assets/fonts/SoDoSans-Bold.woff") format("woff"),
    url("./assets/fonts/SoDoSans-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SoDo Sans Bold Italic";
  src: url("./assets/fonts/SoDoSans-BoldItalic.eot");
  src:
    local("SoDo Sans Bold Italic"),
    local("SoDoSans-BoldItalic"),
    url("./assets/fonts/SoDoSans-BoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/SoDoSans-BoldItalic.woff2") format("woff2"),
    url("./assets/fonts/SoDoSans-BoldItalic.woff") format("woff"),
    url("./assets/fonts/SoDoSans-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "SoDo Sans Black";
  src: url("./assets/fonts/SoDoSans-Black.eot");
  src:
    local("SoDo Sans Black"),
    local("SoDoSans-Black"),
    url("./assets/fonts/SoDoSans-Black.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/SoDoSans-Black.woff2") format("woff2"),
    url("./assets/fonts/SoDoSans-Black.woff") format("woff"),
    url("./assets/fonts/SoDoSans-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SoDo Sans Black Italic";
  src: url("./assets/fonts/SoDoSans-BlackItalic.eot");
  src:
    local("SoDo Sans Black Italic"),
    local("SoDoSans-BlackItalic"),
    url("./assets/fonts/SoDoSans-BlackItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/SoDoSans-BlackItalic.woff2") format("woff2"),
    url("./assets/fonts/SoDoSans-BlackItalic.woff") format("woff"),
    url("./assets/fonts/SoDoSans-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
