/*--------| HTML, Body |---------*/
html {
  font-size: 18px;
}

/*-------| Main |--------*/
main {
  width: $container;
  margin: 0 auto;
  padding: calc($grid-4x - 2px) $grid-2x 0;
}

.kick-status .leftDays {
  font-size: 0.833rem;
  letter-spacing: 2px;
}

.parallel-grid {
  & > div.first-grid {
    padding: 0 calc($grid-3x + 4px);
  }
  & > div.last-grid {
    padding: 0 calc($grid-3x + 4px) $grid-2x;
  }

  &.availability-grid {
    .last-grid {
      background-color: $white500;
      @include roundCorner($grid-2x);
      height: 100%;
    }
  }
}

/*--------| Headings |---------*/
h1.MuiTypography-root {
  font-size: 1.556rem;
  line-height: 1.27;
}

h2.MuiTypography-root {
  font-size: 1.333rem;
}

h3.MuiTypography-root {
  font-size: 1.111rem;
}

h4.MuiTypography-root {
  font-size: 1.09rem;
}

h5.MuiTypography-root {
  font-size: 1.05rem;
}

/*-------| Title |--------*/
.MuiTypography-root.card-header-title {
  font-size: 1.33rem;
  padding: calc($grid-2x + 4px) 0;

  &.request-title {
    padding: calc($grid-2x + 4px) 0 calc($grid-1x + 2px);
  }
}

.MuiList-root {
  &.schedule-list {
    li {
      .left {
        width: $grid-6x;
        padding: calc($grid-2x + 2px) $grid-1x;
        margin-right: $grid-2x;
      }
      .right {
        width: calc(100% - $grid-10x - $grid-5x);
      }
    }
  }
}

/*-------| User Tabs |--------*/
.MuiTabs-root {
  margin-top: 0.5rem;
  &.user-tabs {
    padding-bottom: calc($grid-4x - 2px);
    .MuiTab-root {
      font-size: 1.11rem;
    }
  }
}

/*-------| Card |--------*/
.MuiCard-root {
  &.main-card {
    margin-bottom: calc($grid-2x + 4px);
  }
  .card-header {
    .MuiCardHeader-title {
      font-size: 1.33rem;
    }
  }
}

.parallel-grid {
  & > div:first-child {
    padding-right: $grid-4x;
  }

  & > div:last-child {
    padding-left: $grid-4x;
  }

  .blank-grid {
    padding: calc($grid-4x + 3px) 0;

    &.current-availability,
    &.avail-request {
      padding: calc($grid-4x + 3px) 0;
    }

    &.preferred-current-grid {
      padding: calc($grid-1x * 13.625) 0;
    }
    &.preferred-future-grid {
      padding: calc($grid-1x * 11.625) 0;
    }
  }
}

/*-------| Accordion |--------*/
.acc-wrapper {
  .avail-status {
    span.MuiTypography-root {
      font-size: 0.889rem;
    }
  }

  &.acc-requests {
    .acc-header {
      .label-wrapper {
        .label {
          width: 4rem;

          .MuiTypography-body1 {
            font-size: 0.834rem;
          }
        }
      }
    }
  }
}

.vertical-dotted-lines {
  margin-top: calc($grid-1x - 28px);
}
