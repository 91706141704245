@import "fonts";
@import "reset";

/*-------| Main |--------*/
main {
  padding: calc($grid-2x + 4px) 0 0;
}

.parallel-grid {
  & > div.first-grid {
    padding: 0 calc($grid-1x + 4px) $grid-1x;
  }

  & > div.last-grid {
    padding: 0 calc($grid-1x + 4px) $grid-6x;
  }

  &.availability-grid {
    .last-grid {
      background-color: $white500;

      &.no-data {
        background-color: transparent;
      }
    }
  }

  .blank-grid {
    border: 1px dashed $grey400;
    @include roundCorner($grid-2x);

    &.current-availability,
    &.avail-request {
      padding: calc($grid-4x - 2px) 0;
    }

    &.preferred-current-grid,
    &.preferred-future-grid {
      padding: calc($grid-1x * 9.375) 0;
    }
  }
}

/* -------| Extended Styles |--------*/
.MuiList-root {
  &.schedule-list {
    padding: 0;

    li {
      background-color: $secondary-text;
      border: 1px solid $white400;
      @include roundCorner(calc($grid-3x/2));
      padding: 0;
      margin: 0 0 calc($grid-1x + 4px);

      &:last-child {
        margin-bottom: 0;
      }

      .left {
        text-transform: uppercase;
        width: calc($grid-5x + 2px);
        @include roundCorner(calc($grid-3x/2));
        border: 1px solid $green400;
        background-color: $green800;
        padding: calc($grid-1x + 4px) calc($grid-1x/2);
        color: $primary-text;
        font-family: $SodoSans;

        &.absent-day {
          border: 1px solid $black500;
          background-color: $white400;
        }
      }

      .right {
        width: calc(100% - $grid-10x - 4px);
        padding: 0 $grid-2x;

        .clubTime {
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          padding-top: calc($grid-1x - 2px);
          padding-bottom: calc($grid-1x - 2px);

          & > div {
            margin: calc($grid-1x/2) 0;
          }
        }

        .null-time {
          color: $primary-text-hover;
        }

        .duration {
          color: $black600;
          font-family: $SodoSans;
        }
      }
    }
  }
}

/*-------| Title |--------*/
.MuiTypography-root.card-header-title {
  color: $black350;
  font-family: $SodoSansSemiBold;
  padding: calc($grid-3x + 2px) 0 calc($grid-2x - 2px);

  &.request-title {
    padding: calc($grid-2x + 2px) 0 calc($grid-1x + 2px);
  }
}

/*-------| Kick Of Status |--------*/
.kick-status {
  color: $primary-text-hover;
  padding: calc($grid-1x/2) 0 0;

  .leftDays {
    font-size: 0.833rem;
    letter-spacing: 1px;
  }

  .MuiSvgIcon-root {
    margin-right: calc($grid-1x/2);
  }
}

/*-------| Card |--------*/
.MuiCard-root {
  &.main-card {
    @include boxShadow(none);
    margin-bottom: calc($grid-2x - 2px);

    .card-content {
      padding: 0;
      @include roundCorner(calc($grid-3x/2));
      background-color: $white;

      & > .MuiPaper-root {
        @include roundCorner(calc($grid-3x/2));
        @include boxShadow(none);
      }

      &.current-card {
        border: 1.5px solid $secondary;

        &.available-card {
          background-color: $green950;

          & > .MuiPaper-root {
            background-color: transparent;
          }

          .avail-container {
            background-color: $white;
          }
        }

        &.available-card:focus-visible {
          outline: none;
        }
      }

      &.future-card {
        border: 1.5px solid $green300;

        &.available-card {
          border: 1px solid $secondary;
        }

        &.available-card:focus-visible {
          outline: none;
        }
      }

      &.preference-card {
        padding: $grid-2x $grid-2x;
        background-color: $white500;
        margin-top: calc($grid-2x + 4px);
      }

      &.preference-card:focus-visible {
        outline: none;
      }
    }

    .card-current-content {
      padding: 0;
      @include roundCorner(calc($grid-3x/2));
      background-color: $white;

      & > .MuiPaper-root {
        @include roundCorner(calc($grid-3x/2));
        @include boxShadow(none);
      }

      &.current-card-dash {
        border: 1.5px dashed $secondary;

        &.available-card-dashed {
          border: 1.5px dashed #b4b4b4 !important;
          background-color: #f9f9f9;

          & > .MuiPaper-root {
            background-color: transparent;
          }

          .avail-container {
            background-color: $white;
          }
        }
      }
    }
  }

  &.request-card {
    @include boxShadow(none);
    background-color: transparent;

    .card-content {
      padding: 0;

      .sph-text {
        margin-bottom: calc($grid-2x + 4px);
        margin: 4px 43px 15px 5px;

        span {
          font-family: $SodoSans;
          color: $primary-text-hover;

          &.sph {
            color: $primary-hover;
            margin-left: calc($grid-1x/2);
            cursor: pointer;

            &:hover {
              color: $primary;
            }
          }
        }
      }
    }

    .card-content:focus-visible {
      outline: none;
    }
  }

  &.request-card:focus-visible {
    outline: none;
  }
}

/*-------| Accordion |--------*/
.acc-wrapper {
  .acc-header {
    align-items: flex-start;
    color: $primary-text;
    padding: 0 $grid-1x;

    &.Mui-expanded {
      min-height: auto;

      .MuiAccordionSummary-content {
        margin: calc($grid-2x - 4px) 0 0;
      }
    }

    .MuiAccordionSummary-expandIconWrapper {
      margin-top: calc($grid-1x + 1px);

      .acc-icon {
        color: $primary;
        padding: calc($grid-1x/4);
        font-size: 1.9rem;
      }
    }
  }

  .acc-content {
    padding: $grid-1x;

    h2 {
      font-family: $SodoSansSemiBold;
      color: $primary-text;
      margin-bottom: $grid-1x;
    }

    .avail-container {
      background-color: $white500;
      margin: 0;
      padding: $grid-1x;
      @include roundCorner(calc($grid-3x/2));
    }
  }

  &.acc-requests {
    border: 1px solid $white400;
    @include roundCorner($grid-1x);
    @include boxShadow(none);
    margin-bottom: $grid-2x;

    &::before {
      background-color: transparent;
    }

    &:first-of-type,
    &:last-of-type {
      @include roundCorner($grid-1x);
    }

    &.Mui-expanded:last-of-type {
      margin-bottom: $grid-2x;
    }

    .request-container {
      background-color: $white500;
      padding: $grid-1x;
      @include roundCorner($grid-1x);
      color: $primary-text;

      @extend .MuiList-root;

      .user-msg-container {
        padding: $grid-1x;
      }

      .other-msg-container {
        @include roundCorner($grid-1x);
        background-color: $white;
        padding: $grid-1x;
      }
    }

    .submit-info {
      margin: $grid-1x 0 0;
      border-top: 1px solid $secondary-text;
      color: $primary-text;
    }
  }

  &.acc-requests:focus-visible {
    outline: 2px solid #00754a;
    transition: none;
  }
}

/*-------| Snack Bar |--------*/
.snack-bar {
  .MuiPaper-root {
    @include roundCorner($grid-1x);
    align-items: center;

    .MuiAlert-message {
      font-size: 1.2rem;
      font-family: $SodoSansSemiBold;
    }

    .MuiAlert-action {
      padding-top: 0;

      button {
        svg {
          font-size: 1.66rem;
        }
      }
    }
  }

  &.success {
    .MuiPaper-root {
      background-color: $green650;
      border: 1px solid $darkgreen;

      .MuiAlert-message {
        color: $darkgreen;
      }

      .MuiAlert-action {
        button {
          svg {
            color: $primary-text;
          }
        }
      }
    }
  }

  &.error {
    .MuiPaper-root {
      background-color: $red700; // Set background color to red
      border: 1px solid $red100; // Adjust border color as needed

      .MuiAlert-icon {
        svg {
          fill: $red100; // Adjust icon color as needed
        }
      }

      .MuiAlert-message {
        color: $red100; // Adjust text color as needed
      }

      .MuiAlert-action {
        button {
          svg {
            color: $primary-text;
            fill: $red100;
          }
        }
      }
    }
  }
}

/* Vertical dotted Style */
.vertical-dotted-lines {
  position: relative;
  height: calc($grid-2x - 2px);
  margin-top: calc($grid-1x - 28px);
  width: 90%;
  margin-left: 5%;
}

.vertical-dotted-lines::before,
.vertical-dotted-lines::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
}

.vertical-dotted-lines::before {
  left: 0px;
  border-left: calc($grid-1x - 6px) dotted #b4b4b4;
  align-items: center;
}

.vertical-dotted-lines::after {
  right: 0px;
  border-left: calc($grid-1x - 6px) dotted #b4b4b4;
}

/* Button Styles */
.MuiButtonBase-root {
  &:hover {
    background-color: #f9f9f9;
    @include roundCorner(calc($grid-3x/2));
  }

  &:focus-visible {
    background-color: #f9f9f9;
    outline: 2px solid #00754a;
    @include roundCorner(calc($grid-3x/2));
  }

  &:active {
    background-color: #f9f9f9;
    @include roundCorner(calc($grid-3x/2));
  }
}

edit-btn:focus-visible {
  outline: 2px solid #00754a;
  transition: none;
}
