@import "../../../styles/variables.scss";
@import "../../../styles/colors";
@import "../../../styles/mixins";

.drawer {
  &.bottom {
    .MuiModal-backdrop {
      background-color: rgba(0, 0, 0, 0.3);
    }

    .MuiDrawer-paper {
      @include roundCorner($grid-1x $grid-1x 0 0);
      height: 100%;

      .drawer-scroll {
        overflow-y: none;
        width: 100%;
      }

      .close {
        color: $black;
      }

      .close:focus-visible {
        outline: 2px solid #00754a;
      }

      .titleBox {
        display: flex;
        flex: 1 0 0;
        padding: var(--space-1-x, 0.5rem) var(--space-2-x, 1rem)
          var(--space-half-x, 0.25rem) var(--space-2-x, 1rem);
        justify-content: center;
        align-items: center;
        font-size: 1.2rem;
        align-self: stretch;
        color: var(--Ink-leading, #1e1e1e);
        background: var(--color-surface-level-1, #fff);
        font-family: "SoDo Sans";
        font-style: normal;
        font-weight: 600;
        line-height: 1.25rem;
        z-index: 9999;
      }

      .drawer-title {
        color: var(--Ink-leading, #1e1e1e);
        font-family: "SoDo Sans";
        font-style: normal;
        font-weight: 600;
        line-height: 1.25rem;

        &.current-title {
          margin-bottom: calc($grid-1x/2);
        }
      }
    }

    .buttonControl {
      background: var(--color-surface-level-1, #fff);
      display: flex;
      padding: var(--space-half-x, 0.25rem) var(--space-2-x, 1rem)
        var(--space-1-x, 0.5rem) var(--space-2-x, 1rem);
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
    }

    .storeHours {
      background: var(--color-surface-level-1, #fff);
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
    }

    .Step {
      margin: $grid-1x 0 calc($grid-1x + 2px);
      font-family: $SodoSans;
      color: $primary-text;
      white-space: nowrap;

      &:before {
        font-family: $SodoSans;
        color: $primary;
        @include roundCorner(50%);
        width: calc($grid-3x + 2px);
        height: calc($grid-2x + 4px);
        display: inline-block;
        text-align: center;
        font-size: 1.071rem;
        margin-right: calc($grid-1x - 2px);
        padding: calc($grid-1x - 2px) 0 0;
        border: 1px solid $primary;
      }

      &.active {
        font-family: $SodoSansSemiBold;

        &:before {
          background-color: $secondary-focus;
          border: 0;
          color: $white;
        }
      }

      &.completed {
        color: $primary;

        &:before {
          background-color: $green750;
          border-color: $secondary-hover;
          color: $secondary-hover;
        }
      }

      &.Step1 {
        &:before {
          content: "1";
        }
      }

      &.Step2 {
        margin-top: 2rem;
        &:before {
          content: "2";
        }
      }
    }
  }
}

@media only screen and (max-width: $breakpoint-mobile),
  (max-width: $breakpoint-tablet) {
  .drawer {
    &.bottom {
      .Step {
        padding: 0 $grid-2x 0 $grid-2x;

        &:before {
          width: calc($grid-3x + 4px);
          height: calc($grid-3x - 1px);
          padding: calc($grid-1x - 3px) 0 0;
          margin-right: calc($grid-1x - 2px);
        }
      }
    }
  }
}

@media only screen and (min-width: $breakpoint-sm-mobile),
  (max-width: $breakpoint-lg-tablet) {
  .drawer {
    &.bottom {
      .Step {
        padding: 0 $grid-2x 0 $grid-2x;
        &:before {
          width: calc($grid-3x + 4px);
          height: calc($grid-3x - 1px);
          padding: calc($grid-1x - 3px) 0 0;
          margin-right: calc($grid-1x - 2px);
        }
      }
    }
  }
}

@media only screen and (min-width: $breakpoint-sm-desktop) {
  .drawer {
    &.bottom {
      .MuiDrawer-paper {
        height: 100vh;
        padding: 0;
      }

      .Step {
        &:before {
          width: $grid-4x;
          height: calc($grid-3x + 1px);
          padding-top: calc($grid-1x - 1px);
          margin-right: $grid-1x;
        }
      }
    }
  }
}

@media only screen and (min-width: $breakpoint-normal-desktop) {
  .drawer {
    &.bottom {
      .MuiDrawer-paper {
        height: 100vh;
      }

      .Step {
        font-size: 1.11rem;

        &:before {
          width: calc($grid-4x + 4px);
          height: calc($grid-3x + 5px);
          padding-top: calc($grid-1x - 1px);
          font-size: 1.11rem;
        }
      }
    }
  }
}
