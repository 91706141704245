@import "../../../../styles/variables";
@import "../../../../styles/colors";
@import "../../../../styles/mixins";

.drawer {
  .MuiCard-root.main-card {
    .preference-card {
      border: 1px solid $green750;
    }

    .PreferredItem {
      @include roundCorner($grid-1x);
      background-color: $white;
      border: 1px solid $white400;
      margin: 0 $grid-1x $grid-1x;
      padding: $grid-1x;
      max-width: calc(33.33% - $grid-1x - 4px);
      display: flex;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      &.error {
        border: 1px solid $error-text;
      }

      &.success {
        .MuiFormControl-fullWidth {
          fieldset {
            border: 1px solid $green750;
          }
        }
      }

      .MuiFormControl-fullWidth {
        margin: calc($grid-1x/2) auto;
        text-align: center;
        max-width: calc(12.5 * $grid-1x);

        .MuiSelect-select {
          padding: calc($grid-1x + 2px) calc($grid-2x - 3px) calc($grid-1x/2)
            calc($grid-1x/2);
          border-color: $grey500;
          background-color: $white;
          font-family: $SodoSansSemiBold;
          font-size: 1.286rem;
          color: $primary-text;
          text-align: center;
        }
        .MuiSelect-select:focus-visible {
          outline: 2px solid #00754a;
        }

        fieldset {
          border: 1px solid $grey500;
          @include roundCorner(calc($grid-1x - 2px));
        }
      }
    }

    .preference-card:focus-visible {
      outline: none;
    }

    .PreferredItem:focus-visible {
      outline: none;
    }
  }

  button.confirm-btn {
    width: 100%;
    padding: calc($grid-1x) 0;
    font-size: 1.071rem;
    margin: $grid-1x 0;

    &[disabled] {
      background-color: $white400;
      color: $primary-text-hover;
    }
  }
}

.MuiMenu-list li {
  justify-content: center;
}

@media only screen and (min-width: $breakpoint-sm-desktop) {
  .drawer {
    .date-wrapper {
      width: 350px;
    }

    .date-wrapper:focus-visible {
      outline: 2px solid #00754a;
    }
  }

  .date-calendar {
    &.right-cal {
      margin-left: 0;
    }
  }
}

@media only screen and (min-width: $breakpoint-normal-desktop) {
  .drawer {
    .date-wrapper {
      width: calc(41.25 * $grid-1x);
    }

    .date-calendar {
      &.right-cal {
        margin-left: calc($grid-1x - 2px);
      }
    }
  }
}

@media only screen and (min-width: $breakpoint-normal-desktop) {
  .drawer {
    .date-wrapper {
      width: calc(45 * $grid-1x);
    }
  }
}
