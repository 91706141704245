@import "../../../../../styles/variables";
@import "../../../../../styles/colors";
@import "../../../../../styles/mixins";

.pending-request-wrapper {
  margin-bottom: calc($grid-2x + 4px);

  .acc-wrapper.acc-requests.pending-requests {
    @include roundCorner($grid-1x $grid-1x 0 0);
    margin: 0;
  }

  .pending-cancel-btn {
    background-color: $white;
    border: 1px solid $white400;
    border-top-width: 0;
    color: $secondary-hover;
    padding: calc($grid-1x - 2px);
    @include roundCorner(0 0 $grid-1x $grid-1x);
    cursor: pointer;
    width: 100%;

    #textPendingBtn {
      color: var(--color-ink-on-tertiary, #006241);
      font-family: "SoDo Sans";
      font-size: 0.8125rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.25rem;
      display: flex;
      padding: 0rem 0.25rem;
      align-items: flex-start;
    }

    svg {
      fill: $secondary-hover;
      width: 1rem;
      height: 1rem;
    }
  }

  .pending-edit-btn {
    background-color: $white;
    border: 1px solid $white400;
    border-top-width: 0;
    color: $secondary-hover;
    padding: calc($grid-1x - 2px);
    @include roundCorner(0 0 $grid-1x $grid-1x);
    cursor: pointer;
    width: 50%;

    #textPendingBtn {
      color: var(--color-ink-on-tertiary, #006241);
      font-family: "SoDo Sans";
      font-size: 0.8125rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.25rem;
      display: flex;
      padding: 0rem 0.25rem;
      align-items: flex-start;
    }

    svg {
      fill: $secondary-hover;
      width: 1rem;
      height: 1rem;
    }
  }
  .pending-cancel-btn:focus-visible {
    outline: 0.5px solid #00754a;
  }
}
