/*----| Red |----*/
$red: #b71c1c;
$red100: #9a2a21; //rgb(154, 42, 33); $error-text
$red200: #d53f28; //rgb(213, 63, 40);
$red300: #e53935;
$red400: #f44336;
$red500: #ef5350;
$red600: #fabdab; //rgb(250, 189, 171)
$red700: #fcd7cd; //rgb(252, 215, 205)
$red800: #ef9a9a;
$red900: #e57373;

/*----| Green |----*/
$green: #1e3832; //rgb(30, 57, 50) //$primary
$green100: #004f35; //rgb(0, 79, 53);  //$secondary-focus
$green200: #006241; // rgb(0, 98, 65);
$green300: #00754a; //rgb(0, 117, 74); //$secondary, $primary-hover
$green400: #008d5b; //rgb(0, 141, 91);
$green500: #08a36a; //rgb(0, 163, 106);;
$green600: #0cc783; //rgb(12, 199, 131);
$green650: #95cc76; //rgb(149, 204, 118);
$green700: #bfe0ad; //rgb(191, 224, 173);
$green750: #d4e9e2; //rgb(212, 233, 226);
$green800: #d2ebe0; //rgb(210, 235, 224);
$green900: #e8f4ee; //rgb(232, 244, 238);
$green950: #f6fbf9; //rgb(246, 251, 249)
$darkgreen: #1b4a22; //rgb(27, 74, 34);

/*----| Blue |----*/
$blue: #064163; //rgb(6, 65, 99);
$blue100: #005a8a;
$blue200: #1565c0;
$blue300: #1976d2;
$blue400: #1e88e5;
$blue500: #2196f3;
$blue600: #42a5f5;
$blue700: #64b5f6;
$blue800: #90caf9;
$blue900: #bbdefb;

/*----| Yellow |----*/
$yellow: #efc60d;
$yellow100: #e9c41f;
$yellow300: #f7d87d; // rgb(247, 216, 125)
$yellow400: #fae8b1; //rgb(250, 232, 177);

/*----| Black |----*/
$black: #000;
$black100: #0d0c0c;
$black200: #161616; //rgb(22, 22, 22);
$black300: #1e1e1e; //rgb(30, 30, 30); //$primary-text;
$black350: #1a1a1a; //rgb(26, 26, 26);
$black400: #525252; //rgb(82, 82, 82);
$black500: #818181;
$black600: #5f5f5f; //rgb(95, 95, 95); //$primary-text-hover
$black700: #cecece; //rgb(206, 206, 206);

/*----| White |----*/
$white: #fff;
$white100: #dadada; //rgb(218, 218, 218);
$white200: #d9d9d9;
$white300: #f0f0f0;
$white400: #e7e7e7; //rgb(231, 231, 231);
$white500: #f9f9f9; //rgb(249, 249, 249);

/*----| Grey |----*/
$grey: #212121;
$grey100: #424242;
$grey200: #4a4a4a; //rgb(74, 74, 74);
$grey300: #605f5f;
$grey400: #9e9e9e;
$grey500: #b4b4b4; //rgb(180, 180, 180);
$grey600: #e0e0e0;
$grey700: #e4e3e3;
$grey800: #f8f8f8;
$grey900: #fafafa;

/*----| Brown |----*/
$brown: #645123; //rgb(100, 81, 35); /$warning-text

/*----| Primary |----*/
$primary: $green;
$primary-hover: $green300;
$primary-text: $black300;
$primary-text-hover: $black600;

/*----| Secondary |----*/
$secondary: $green300;
$secondary-hover: $green200;
$secondary-focus: $green100;
$secondary-text: $white;
$secondary-text-hover: $white200;

/*----| Success |----*/
$success: $green700;
$success-hover: rgba($green600, 0.9);
$success-text: $darkgreen;

/*----| Warning |----*/
$warning: $yellow400;
$warning-hover: $yellow100;
$warning-text: $brown;

/*----| Error |----*/
$error: $red700;
$error-hover: $red500;
$error-text: $red100;

/*----| Info |----*/
$info-color: $blue400;
$info-hover: $blue500;
$info-text: $blue;
$info-text-hover: $blue300;

/*----| Tertieary |----*/
$tertiary: rgba($grey200, 0.05);
$tertiary-hover: #1273e6;
$tertiary-text: #009476;
$tertiary-text-hover: #00589e;

$background-color: #333333;
$background-color: #f2f2f2;
$surface-color: #ffffff;
$surface-color: #eaeaea;
$surface-variant-color: #008f99;
$surface-variant-color: #ffffff;
$outline-color: transparent;
$grid-a: #fbfbfb;
$loading-bg: #ea0f00;
