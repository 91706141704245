@import "../../../styles/variables";
@import "../../../styles/colors";
@import "../../../styles/mixins";

.dialog {
  @media screen and (max-width: $breakpoint-xs-mobile),
    (max-width: $breakpoint-sm-mobile),
    (max-width: $breakpoint-mobile) {
    width: 30rem;
    min-height: 90%;
    margin-left: auto;
    margin-right: auto;

    .MuiPaper-root {
      margin: 0.5rem;
      overflow-y: hidden;
    }

    .MuiDialog-paper {
      min-height: 55rem;
    }
  }

  .MuiDialog-paper {
    overflow-x: hidden;
    border-radius: 2rem;
    background: var(--color-surface-level-1, #fff);
    box-shadow: var(--shadow-x-modal, 0px) var(--shadow-y-modal, 4px)
      var(--shadow-blur-modal, 16px) 0px
      var(--shadow-color-modal, rgba(0, 0, 0, 0.4));
  }
}

#infobutton {
  font-size: 1.11rem;
  min-height: auto;
  padding: 5px 0;
  margin-right: 24px;
  text-transform: none;
  font-size: 1.143rem;
  color: #5f5f5f;
  font-family: "SoDo Sans Semi Bold", sans-serif;
}

.box1 {
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;

  @media only screen and (min-width: $breakpoint-normal-desktop),
    (min-width: $breakpoint-sm-desktop),
    (min-width: $breakpoint-tablet) {
    padding: var(--space-5-x, 1.5rem) var(--space-7-x, 2.5rem)
      var(--space-4-x, 1rem) var(--space-7-x, 2.5rem);
    gap: var(--space-2-x, 1rem);
  }

  @media screen and (max-width: $breakpoint-xs-mobile),
    (max-width: $breakpoint-sm-mobile),
    (max-width: $breakpoint-mobile) {
    padding: var(--space-4-x, 3rem) var(--space-4-x, 2.5rem)
      var(--space-3-x, 2.5rem) var(--space-4-x, 3rem);
    gap: var(--space-1-x, 1rem);
  }
}

#modal-title {
  color: var(--color-ink-leading, #1a1a1a);
  text-align: center;
  font-family: "SoDo Sans";
  // font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.04rem;
  align-self: stretch;

  @media only screen and (min-width: $breakpoint-normal-desktop),
    (min-width: $breakpoint-sm-desktop),
    (min-width: $breakpoint-tablet) {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  @media screen and (max-width: $breakpoint-xs-mobile),
    (max-width: $breakpoint-sm-mobile),
    (max-width: $breakpoint-mobile) {
    font-size: 2rem;
    line-height: 2.5rem;
  }
}

#subtitle {
  @media only screen and (min-width: $breakpoint-normal-desktop),
    (min-width: $breakpoint-sm-desktop),
    (min-width: $breakpoint-tablet) {
    font-size: 0.9rem;
  }

  @media screen and (max-width: $breakpoint-xs-mobile),
    (max-width: $breakpoint-sm-mobile),
    (max-width: $breakpoint-mobile) {
    font-size: 1rem;
  }

  color: var(--color-ink-supporting, #666);
  text-align: center;
  font-family: "SoDo Sans";
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.scrollableDiv {
  @media only screen and (min-width: $breakpoint-normal-desktop),
    (min-width: $breakpoint-sm-desktop),
    (min-width: $breakpoint-tablet) {
    padding: 1rem;
    margin: 0.5rem;
    border-radius: var(--radius-minimal, 0.5rem);
    background: var(--color-surface-level-2, #f3f3f3);
  }

  @media screen and (max-width: $breakpoint-xs-mobile),
    (max-width: $breakpoint-sm-mobile),
    (max-width: $breakpoint-mobile) {
    padding: 0.5rem;
    background: var(--color-surface-level-2, #f3f3f3);
  }

  align-items: flex-start;
  position: static;
  gap: var(--space-none, 0px);
  flex: 1 0 0;
  align-self: stretch;
}

.separator {
  margin-top: 16px;
  margin-bottom: 16px;
  position: static;
  height: 0.03125rem;
  align-self: stretch;
  background: var(--color-line-loud, #808080);
}

.list {
  margin-left: 1rem;
}

.box2 {
  flex-direction: column;
  position: static;
  overflow-y: scroll;
  display: flex;
  padding: 0rem var(--space-2-x, 1rem);
  align-items: flex-start;
  gap: var(--space-none, 0rem);
  flex: 1 0 0;
  align-self: stretch;

  @media only screen and (min-width: $breakpoint-normal-desktop),
    (min-width: $breakpoint-sm-desktop),
    (min-width: $breakpoint-tablet) {
    max-height: 16rem;
  }

  @media screen and (max-width: $breakpoint-xs-mobile),
    (max-width: $breakpoint-sm-mobile),
    (max-width: $breakpoint-mobile) {
    max-height: 24rem;
  }
}

#step {
  color: var(--color-ink-hero, #00754a);
  font-family: "SoDo Sans";
  font-size: 0.6875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1rem;
  letter-spacing: 0.0825rem;
  text-transform: uppercase;
}

#info-header {
  color: var(--color-ink-leading, #1a1a1a);
  font-family: "SoDo Sans";
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem;
}

#explanation {
  align-self: stretch;
  color: var(--color-ink-supporting, #666);
  font-family: "SoDo Sans";
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
}

#list-text {
  color: var(--color-ink-leading, #1a1a1a);
  font-family: "SoDo Sans";
  font-style: normal;
  font-weight: 400;
  line-height: 140%;

  @media screen and (max-width: $breakpoint-xs-mobile),
    (max-width: $breakpoint-sm-mobile),
    (max-width: $breakpoint-mobile) {
    font-size: 0.75rem;
  }

  @media only screen and (min-width: $breakpoint-normal-desktop),
    (min-width: $breakpoint-sm-desktop),
    (min-width: $breakpoint-tablet) {
    font-size: 0.625rem;
  }
}

#footer {
  @media screen and (max-width: $breakpoint-xs-mobile),
    (max-width: $breakpoint-sm-mobile),
    (max-width: $breakpoint-mobile) {
    font-size: 1rem;
    font-weight: 400;
    line-height: 140%;
  }

  @media only screen and (min-width: $breakpoint-normal-desktop),
    (min-width: $breakpoint-sm-desktop),
    (min-width: $breakpoint-tablet) {
    font-size: 0.75rem;
    line-height: 1rem;
  }

  color: var(--color-ink-supporting, #666);
  text-align: center;
  font-weight: 400;
  font-style: normal;
  font-family: "SoDo Sans";
  flex: 1 0 0;
}

.box3 {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;

  @media screen and (max-width: $breakpoint-xs-mobile),
    (max-width: $breakpoint-sm-mobile),
    (max-width: $breakpoint-mobile) {
    padding: var(--space-1-x, 3rem) var(--space-3-x, 1.5rem);
  }

  @media only screen and (min-width: $breakpoint-normal-desktop),
    (min-width: $breakpoint-sm-desktop),
    (min-width: $breakpoint-tablet) {
    padding: var(--space-1-x, 0.5rem) var(--space-3-x, 1.5rem);
  }
}

.box4 {
  @media only screen and (min-width: $breakpoint-normal-desktop),
    (min-width: $breakpoint-sm-desktop),
    (min-width: $breakpoint-tablet) {
    padding: var(--space-3-x, 1.5rem) var(--space-8-x, 4rem)
      var(--space-7-x, 3.5rem) var(--space-8-x, 4rem);
  }

  @media screen and (max-width: $breakpoint-xs-mobile),
    (max-width: $breakpoint-sm-mobile),
    (max-width: $breakpoint-mobile) {
    padding: var(--Padding, 1.25rem) var(--space-2-x, 1rem);
  }

  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: var(--space-2-x, 1rem);
  align-self: stretch;
}

#button {
  @media only screen and (min-width: $breakpoint-normal-desktop),
    (min-width: $breakpoint-sm-desktop),
    (min-width: $breakpoint-tablet) {
    width: 11.25rem;
    height: 3rem;
    padding: 0rem var(--space-6-x, 3rem);
  }

  @media screen and (max-width: $breakpoint-xs-mobile),
    (max-width: $breakpoint-sm-mobile),
    (max-width: $breakpoint-mobile) {
    height: 3rem;
    padding: 0rem var(--space-5-x, 2.5rem);
    flex: 1 0 0;
  }

  border-radius: var(--radius-friendly, 1rem);
  background: var(--color-interactive-primary, #00754a);
  display: flex;
  justify-content: center;
  align-items: center;
}

#button-text {
  color: var(--color-ink-on-primary, #fff);
  font-family: "SoDo Sans";
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
}
