@import "../../../styles/variables";
@import "../../../styles/colors";
@import "../../../styles/mixins";

/*-------| Preferences |--------*/
.future-card {
  .kick-status {
    padding-top: calc($grid-2x - 4px);
  }
}

.date-header-left {
  width: calc(100% - calc($grid-5x - 2px));
}

.edit-icon {
  svg {
    fill: $primary-text-hover;
  }
}

#error-message-preference-title {
  color: var(--Ink-error, #9a2a21);
  line-height: normal;
  font-family: $SodoSansSemiBold;
  font-size: 20px;

  @media screen and (min-width: $breakpoint-xs-mobile) and (max-width: $breakpoint-tablet) {
    font-size: 15px;
  }
}

.warning-icon {
  color: #9a2a21;
  height: 24px;
  width: 24px;
  padding: 3px 1.5px 2.192px 1.5px;

  @media screen and (min-width: $breakpoint-xs-mobile) and (max-width: $breakpoint-tablet) {
    width: 15.75px;
    height: 14.106px;
    flex-shrink: 0;
  }

  justify-content: center;
  align-items: center;
}

#error-message-preference-subtitle {
  color: var(--Ink-supporting, #5f5f5f);
  line-height: normal;
  margin-bottom: $grid-2x;
  margin-top: $grid-1x;
  font-size: 18px;
  font-family: $SodoSansSemiBold;

  @media screen and (min-width: $breakpoint-xs-mobile) and (max-width: $breakpoint-tablet) {
    font-size: 14px;
    font-family: $SodoSans;
  }
}

.error-block {
  display: flex;
  margin-top: $grid-3x;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
}

.error-div {
  display: flex;
  flex-direction: column;
  margin-left: $grid-1x;

  @media screen and (min-width: $breakpoint-xs-mobile) and (max-width: $breakpoint-tablet) {
    margin-bottom: $grid-5x;
  }
}

.MuiGrid-container {
  &.preference-item-container {
    padding: calc($grid-1x + 4px) 0 0;

    .PreferredItem {
      padding: calc($grid-1x/2) calc($grid-1x/2) calc($grid-1x + 4px);
      border: 1px solid $white400;
      @include roundCorner($grid-1x);
      max-width: calc(33.33% - $grid-1x);
      background-color: $white;

      h1 {
        padding: calc($grid-1x/2) 0;
      }

      .PreferredItemValue {
        font-size: "1.21rem";
      }
    }

    .PreferredItem:focus-visible {
      outline: none;
    }
  }
}

@media only screen and (min-width: $breakpoint-sm-desktop) {
  .MuiGrid-container {
    &.preference-item-container {
      .PreferredItem {
        padding: $grid-1x;
        margin: 0 $grid-1x;
        max-width: calc(32.33% - $grid-2x);
      }

      .PreferredItem:focus-visible {
        outline: none;
      }
    }
  }
}

@media only screen and (min-width: $breakpoint-normal-desktop) {
  /*-------| Preferences |--------*/
  .MuiGrid-container {
    &.preference-item-container {
      padding: $grid-2x 0;
    }
  }
}
