@import "../../../styles/variables";
@import "../../../styles/colors";
@import "../../../styles/mixins";

/*-------| Emp ID Modal | -----*/
.login-overlay {
  background-color: rgba($black, 0.3);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  display: flex;
  z-index: 4;

  .login-modal {
    background-color: $white;
    width: calc(calc(3 * $grid-10x) + $grid-1x);
    padding: $grid-2x;
    @include roundCorner($grid-1x);
    @include boxShadow(0 0 $grid-1x calc($grid-1x/2) rgba($black, 0.3));

    h2 {
      margin-bottom: $grid-2x;
    }

    input {
      padding: calc($grid-2x - 3px) calc($grid-1x + 3px) calc($grid-2x);
    }

    button {
      margin: calc($grid-2x + 2px) 0 $grid-1x;
      font-size: 1.3rem;
      padding: 0.5rem 1rem;
    }

    .error {
      margin: $grid-2x 0 0;
      color: $error-text;
      font-size: 1.1rem;
    }
  }
}
