@import "../../../styles/variables.scss";
@import "../../../styles/colors";
@import "../../../styles/mixins";

#error-message-availability-title {
  color: var(--Ink-error, #9a2a21);
  line-height: normal;
  font-family: $SodoSansSemiBold;
  font-size: 20px;

  @media screen and (min-width: $breakpoint-xs-mobile) and (max-width: $breakpoint-tablet) {
    font-size: 15px;
  }
}

.create-header {
  height: 10vh;
  display: flex;
  padding: var(--space-2-x, 16px) var(--space-4-x, 32px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--space-none, 0px);
  align-self: stretch;
  border-radius: var(--radius-none, 0px);
  border-bottom: 0.5px solid var(--color-line-quiet, #bfbfbf);
  background: var(--color-surface-level-1, #fff);
  flex-grow: 1;
}

.create-footer {
  display: flex;
  height: 2vh;
  margin-bottom: -2rem;
  padding: var(--space-2-x, 4px) var(--space-3-x, 16px);
  justify-content: flex-end;
  align-items: center;
  align-self: stretch;
  border-top: 0.5px solid var(--color-line-quiet, #bfbfbf);
  background: var(--color-surface-level-1, #fff);
  flex-grow: 1;
}

.buttonArea {
  margin-top: 1.5rem;
  display: flex;
  padding: var(--space-none, 0px) 0px;
  justify-content: flex-end;
  align-items: center;
  gap: var(--space-1-x, 8px);
  flex: 1 0 0;
}

#header-text {
  color: var(--Ink-leading, #1e1e1e);

  font-family: "SoDo Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  display: flex;
  align-items: center;
  align-self: stretch;
}

.warning-icon {
  color: #9a2a21;
  height: 24px;
  width: 24px;
  padding: 3px 1.5px 2.192px 1.5px;

  @media screen and (min-width: $breakpoint-xs-mobile) and (max-width: $breakpoint-tablet) {
    width: 15.75px;
    height: 14.106px;
    flex-shrink: 0;
  }

  justify-content: center;
  align-items: center;
}

#error-message-availability-subtitle {
  color: var(--Ink-supporting, #5f5f5f);
  line-height: normal;
  margin-bottom: $grid-2x;
  margin-top: $grid-1x;
  font-size: 18px;
  font-family: $SodoSansSemiBold;

  @media screen and (min-width: $breakpoint-xs-mobile) and (max-width: $breakpoint-tablet) {
    font-size: 14px;
    font-family: $SodoSans;
  }
}

.error-block {
  display: flex;
  margin-top: $grid-3x;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
}

.error-div {
  display: flex;
  flex-direction: column;
  margin-left: $grid-1x;

  @media screen and (min-width: $breakpoint-xs-mobile) and (max-width: $breakpoint-tablet) {
    margin-bottom: $grid-5x;
  }
}

.clear-date {
  &.MuiIconButton-root {
    padding: 1px;
  }
}

.next-btn-box {
  padding: 0 0.5rem 0 0.5rem;
}

.availableHours {
  color: var(--color-ink-leading, #1a1a1a);
  font-family: "SoDo Sans";
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.comment {
  position: relative;
  margin: $grid-1x 0;
  background-color: $white;
  border: 1px solid $black700;
  @include roundCorner($grid-1x);

  .optional {
    color: $primary-text-hover;
    position: absolute;
    bottom: calc($grid-1x + 2px);
    left: calc($grid-1x + 2px);
  }

  .characters-left {
    color: $primary-text-hover;
    position: absolute;
    bottom: calc($grid-1x + 2px);
    right: calc($grid-1x + 2px);
  }

  .comment-box {
    width: 100%;
    padding-bottom: $grid-4x;
  }

  .comment-box:focus-visible {
    outline: 2px solid #00754a;
  }

  .text-area {
    @include roundCorner($grid-1x);
    padding: $grid-1x;
    background-color: $white;
    border: 0;
    resize: none;
    font-size: 1rem;

    &::placeholder {
      color: $primary-text-hover;
      font-family: $SodoSansLight;
    }

    &:focus-visible {
      outline: 2px solid #00754a;
    }
  }
}

.desktopIdealShiftPopup {
  display: flex;
  width: 31.25rem;
  padding: var(--space-none, 0rem);
  flex-direction: column;
  align-items: center;
  gap: var(--space-none, 0rem);
  flex: 1 0 0;
  border-radius: var(--radius-friendly, 1rem);
  background: var(--color-surface-background, #f9f9f9);
  box-shadow:
    0px 24px 38px 0px rgba(0, 0, 0, 0.07),
    0px 9px 46px 0px rgba(0, 0, 0, 0.06),
    0px 11px 15px 0px rgba(0, 0, 0, 0.1);
  margin-left: auto;
  margin-right: auto;
  align-self: center;
  z-index: 9999;
  height: 90%;
}

#idealShiftHourTotal {
  color: var(--color-ink-hero, #00754a);
  text-align: right;
  font-family: "SoDo Sans Light", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
}

#idealShiftGoIcon {
  justify-content: center;
  padding-right: 0;
  padding-left: 0;
  margin-right: -0.5rem;
  align-items: center;
  gap: var(--space-none, 0px);
}
