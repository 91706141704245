@import "../../../../../styles/variables.scss";
@import "../../../../../styles/colors";
@import "../../../../../styles/mixins";

.containerDiv {
  width: 8%;
}

#create-submit-btn {
  display: flex;
  height: 1.8rem;
  padding: 0rem 0.5rem;
  justify-content: center;
  align-items: center;
  border-radius: var(--radius-minimal, 0.5rem);
  background: var(--color-interactive-primary, #00754a);
  color: var(--color-ink-on-primary, #fff);
  text-transform: none;
}

.add-btn-block {
  display: flex;
  justify-content: flex-end;
  display: flex;
  width: 100%;
}

#divider-ideal-shifts {
  width: 100%;
  border: 0.5px solid var(--color-line-loud, #808080);
  color: #808080;
  margin-top: var(--space-2-x, 1.25rem);
}

#timer-picker {
  width: calc(50% - $grid-1x - 4px);
  border-radius: var(--Minimal, 0.5rem);
  background: var(--color-surface-level-1, #fff);

  .MuiSelect-select {
    color: $primary-text-hover;
    font-family: $SodoSans;
    padding: calc($grid-1x + 3px) calc($grid-2x + 4px) calc($grid-1x + 3px)
      $grid-1x;
  }

  fieldset {
    @include roundCorner($grid-1x);
    border: 1px solid $grey500;
  }
}

#timer-picker-selected {
  width: calc(50% - $grid-1x - 4px);
  border-radius: var(--Minimal, 0.5rem);
  background: var(--color-surface-level-1, #fff);

  .MuiSelect-select {
    color: $primary-text-hover;
    font-family: $SodoSans;
    padding: calc($grid-1x + 3px) calc($grid-2x + 4px) calc($grid-1x + 3px)
      $grid-1x;
  }

  fieldset {
    border: 1px solid $grey500;
  }

  border: 1px solid #00754a;
}

.time-wrapper {
  margin: calc($grid-1x - 2px) 0;

  .time-section {
    width: calc(100% - $grid-4x);

    .time-divider {
      margin: 0 calc($grid-1x - 5px);
      width: calc($grid-2x + 2px);
      background: url("../../../../../styles/assets/svg/arrow-line-sm.svg")
        no-repeat;
      background-position: right 0;
      background-size: calc($grid-2x + 2px) calc($grid-2x - 3px);
      height: calc($grid-2x - 2px);
    }
  }

  .MuiTextField-root {
    min-width: auto;
  }

  .remove-time {
    color: $primary-text;
    padding: calc($grid-1x/2);

    svg {
      font-size: 1.8rem;
    }
  }
}

.dropdown-start-end {
  display: flex;
  width: 9.625rem;
  padding: 0.625rem;
  justify-content: space-between;
  align-items: center;

  &.grey {
    border-radius: var(--Minimal, 0.5rem);
    border: 1px solid var(--color-line-loud, #808080);
    background: var(--color-surface-level-1, #fff);
  }

  &.green {
    border-radius: var(--Minimal, 0.5rem);
    border: 1px solid var(--color-line-hero, #00754a);
    background: var(--color-surface-level-1, #fff);
  }
}

#timer-picker {
  width: calc(50% - $grid-1x - 4px);

  .MuiSelect-select {
    color: $primary-text-hover;
    font-family: $SodoSans;
    padding: calc($grid-1x + 3px) calc($grid-2x + 4px) calc($grid-1x + 3px)
      $grid-1x;
  }

  fieldset {
    @include roundCorner($grid-1x);
    border: 1px solid $grey500;
  }
}

.time-wrapper {
  margin: calc($grid-1x - 2px) 0;

  .time-section {
    width: calc(100% - $grid-6x - 4px);

    .time-divider {
      margin: 0 calc($grid-1x - 5px);
      width: calc($grid-2x + 2px);
      background: url("../../../../../styles/assets/svg/arrow-line-sm.svg")
        no-repeat;
      background-position: right 0;
      background-size: calc($grid-2x + 2px) calc($grid-2x - 3px);
      height: calc($grid-2x - 2px);
    }
  }

  .MuiTextField-root {
    min-width: auto;
  }

  .remove-time {
    color: $primary-text;
    padding: calc($grid-1x/2);

    svg {
      font-size: 1.8rem;
    }
  }
}

.ideal-shifts-container {
  padding: var(--space-2-x, 1rem);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.25rem;
  overflow-y: scroll;
  align-self: stretch;
  border-radius: var(--Friendly, 1rem) var(--radius-friendly, 1rem) 0rem 0rem;
  background: var(--color-surface-background, #f9f9f9);
}

.dropdown {
  display: flex;
  width: 5.625rem;
  padding: 0.625rem;
  justify-content: space-between;
  align-items: center;
  border-radius: var(--Minimal, 0.5rem);
  border: 1px solid var(--color-line-loud, #808080);
  background: var(--color-surface-level-1, #fff);
}

.dropdownStack {
  display: flex;
  width: 2rem;
  height: 2rem;
  align-items: center;
  gap: 0.25rem;
}

#preferenceText {
  display: flex;
  align-items: center;
  gap: var(--space-half-x, 0.25rem);
  align-self: stretch;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.time-divider-arrow {
  margin: 0 calc($grid-1x - 5px);
  width: calc($grid-2x + 2px);
  background: url("../../../../../styles/assets/svg/arrow-line-sm.svg")
    no-repeat;
  background-position: right 0;
  background-size: calc($grid-2x + 2px) calc($grid-2x - 3px);
  height: calc($grid-2x - 2px);
}

@media only screen and (max-width: $breakpoint-sm-mobile),
  (max-width: $breakpoint-mobile),
  (min-width: $breakpoint-tablet),
  (max-width: $breakpoint-lg-tablet) {
  .paper-is {
    position: fixed;
    display: flex;
    z-index: 9999;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
  }

  #message {
    color: var(--color-ink-supporting, #666);
    font-family: "SoDo Sans";
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    align-self: stretch;
  }

  .ideal-shifts-box {
    display: flex;
    width: 23.5rem;
    padding-top: var(--space-2-x, 1rem);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--space-1-x, 0.5rem);
  }

  #timer-picker {
    .MuiSelect-select {
      padding: calc($grid-1x + 3px) calc($grid-4x - 2px) calc($grid-1x + 3px)
        calc($grid-1x + 1px);
    }
  }

  .time-wrapper {
    display: flex;
    padding: 0rem var(--space-none, 0rem) 0rem 0rem;
    align-items: center;
  }

  .dropdown-start-end {
    display: flex;
    margin-bottom: 1rem;
    width: 10.625rem;
    padding: 0.625rem;
    justify-content: space-between;
    align-items: center;

    &.grey {
      border-radius: var(--Minimal, 0.5rem);
      border: 1px solid var(--color-line-loud, #808080);
      background: var(--color-surface-level-1, #fff);
    }

    &.green {
      border-radius: var(--Minimal, 0.5rem);
      border: 1px solid var(--color-line-hero, #00754a);
      background: var(--color-surface-level-1, #fff);
    }
  }

  .messageBox {
    display: flex;
    margin: 1rem;
    padding: 0.625rem 0.625rem 0.75rem 0.625rem;
    flex-direction: row;
    gap: var(--space-none, 0rem);
    align-self: stretch;
    border-radius: var(--radius-minimal, 0.5rem);

    &.info {
      background: var(--color-surface-info, #cce5f2);
    }

    &.success {
      background: var(--color-surface-success, #d1e6b5);
    }
  }

  .messageContainer {
    display: flex;
    padding: var(--space-none, 0rem);
    width: 100%;
    padding-right: 0.8rem;
    gap: var(--space-1-x, 0.9rem);
    align-self: stretch;
  }

  .request-condition {
    padding: calc($grid-1x/2) 0 $grid-1x;
    width: 16rem;
    height: auto;
    color: $primary-text-hover;
    display: flex;
    flex-direction: column;
    align-self: stretch;
    width: 80%;
  }

  #mainText {
    color: var(--color-ink-leading, #1a1a1a);
    font-family: "SoDo Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 0.2rem;
    line-height: normal;
  }

  #subText {
    color: var(--color-ink-leading, #1a1a1a);
    font-family: "SoDo Sans";
    font-size: 0.83rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4rem;
  }

  #subTextHour {
    color: var(--color-ink-leading, #1a1a1a);
    font-family: "SoDo Sans";
    font-size: 0.83rem;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
  }

  #total-hours {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    padding-top: 0.2rem;
    align-self: stretch;
    width: 13%;
    padding-left: 2%;
    padding-right: 2%;
  }

  #totalHourValue {
    color: var(--color-ink-leading, #1a1a1a);
    text-align: right;
    font-family: "SoDo Sans";
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 400;
    padding: var(--space-none, 0rem);
    gap: var(--space-1-x, 0.5rem);
    align-self: stretch;
    line-height: normal;
  }

  .add-more-time {
    color: $primary-text;
    padding: calc($grid-1x/2);

    svg {
      font-size: 1.8rem;
      color: $primary-text;
    }
  }
}

@media only screen and (min-width: $breakpoint-normal-desktop),
  (min-width: $breakpoint-large-desktop),
  (min-width: $breakpoint-sm-desktop) {
  .titleBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--space-1-x, 0.5rem);
    align-self: stretch;
    width: 100%;
    margin-top: 0.5rem;
    padding: 0;
  }

  #titleDesktop {
    color: #1a1a1a;
    text-align: center;
    font-family: "SoDo Sans";
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2rem;
  }

  .buttonContainer {
    display: flex;
    padding: 1rem;
    align-items: flex-start;
    gap: 1rem;
    align-self: stretch;
    background: #fff;
  }

  #textA {
    color: var(--color-ink-supporting, #666);
    font-family: "SoDo Sans";
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
  }

  #textB {
    color: var(--color-ink-leading, #1a1a1a);
    font-family: "SoDo Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
  }

  .paper-is {
    position: absolute;
    display: flex;
    z-index: 9999;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
  }

  .ideal-shifts-box {
    display: flex;
    width: 23rem;
    padding-top: var(--space-2-x, 1rem);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--space-1-x, 0.5rem);
  }

  #total-hours {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    align-self: stretch;
    width: 14%;
  }

  #totalHourValue {
    color: var(--color-ink-leading, #1a1a1a);
    text-align: right;
    font-family: "SoDo Sans";
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 400;
    padding: var(--space-none, 0rem);
    gap: var(--space-1-x, 0.5rem);
    align-self: stretch;
    line-height: normal;
  }

  .messageBox {
    display: flex;
    padding: 0.625rem 0.625rem 0.75rem 0.625rem;
    flex-direction: row;
    gap: var(--space-none, 0rem);
    align-self: stretch;
    border-radius: var(--radius-minimal, 0.5rem);
    margin-top: 0;

    &.info {
      background: var(--color-surface-info, #cce5f2);
    }

    &.success {
      background: var(--color-surface-success, #d1e6b5);
    }
  }

  .messageContainer {
    display: flex;
    padding: var(--space-none, 0rem);
    width: 100%;
    padding-right: 0.8rem;
    gap: var(--space-1-x, 0.9rem);
    align-self: stretch;
  }

  .request-condition {
    padding: calc($grid-1x/2) 0 $grid-1x;
    width: 16rem;
    height: auto;
    color: $primary-text-hover;
    display: flex;
    flex-direction: column;
    align-self: stretch;
    width: 80%;
  }

  #mainText {
    color: var(--color-ink-leading, #1a1a1a);
    font-family: "SoDo Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 0.2rem;
    line-height: normal;
  }

  #subText {
    color: var(--color-ink-leading, #1a1a1a);
    font-family: "SoDo Sans";
    font-size: 0.83rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4rem;
  }

  #subTextHour {
    color: var(--color-ink-leading, #1a1a1a);
    font-family: "SoDo Sans";
    font-size: 0.83rem;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
  }

  #total-hours {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    padding-top: 0.2rem;
    align-self: stretch;
    width: 15%;
    padding-left: 2%;
    padding-right: 2%;
  }

  #totalHourValue {
    color: var(--color-ink-leading, #1a1a1a);
    text-align: right;
    font-family: "SoDo Sans";
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 400;
    padding: var(--space-none, 0rem);
    gap: var(--space-1-x, 0.5rem);
    align-self: stretch;
    line-height: normal;
  }

  #message {
    color: var(--color-ink-supporting, #666);
    text-align: center;
    font-family: "SoDo Sans";
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
  }

  #timer-picker {
    width: calc(50% - $grid-1x - 7px);

    .MuiSelect-select {
      color: $primary-text-hover;
      font-family: $SodoSans;
      padding: calc($grid-1x + 3px) calc($grid-2x + 4px) calc($grid-1x + 3px)
        $grid-1x;
    }

    fieldset {
      @include roundCorner($grid-1x);
      border: 1px solid $grey500;
    }
  }

  #timer-picker-selected {
    width: calc(50% - $grid-1x - 7px);
    border-radius: var(--Minimal, 0.5rem);
    background: var(--color-surface-level-1, #fff);

    .MuiSelect-select {
      color: $primary-text-hover;
      font-family: $SodoSans;
      padding: calc($grid-1x + 3px) calc($grid-2x + 4px) calc($grid-1x + 3px)
        $grid-1x;
    }

    fieldset {
      border: 1px solid $grey500;
    }

    border: 1px solid #00754a;
  }
}

@media only screen and (max-width: $breakpoint-mobile) {
  .paper-is {
    position: fixed;
    display: flex;
    z-index: 9999;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
  }

  #textA {
    color: var(--color-ink-supporting, #666);
    font-family: "SoDo Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
  }

  #textB {
    color: var(--color-ink-leading, #1a1a1a);
    font-family: "SoDo Sans";
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
  }

  .ideal-shifts-box {
    display: flex;
    width: 23.5rem;
    padding-top: var(--space-2-x, 1rem);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--space-1-x, 0.5rem);
  }

  .time-wrapper {
    .time-section {
      width: calc(100% - $grid-4x - 4px);
    }
  }

  .messageBox {
    display: flex;
    padding: 0.625rem 0.625rem 0.75rem 0.625rem;
    flex-direction: row;
    gap: var(--space-none, 0rem);
    align-self: stretch;
    border-radius: var(--radius-minimal, 0.5rem);

    &.info {
      background: var(--color-surface-info, #cce5f2);
    }

    &.success {
      background: var(--color-surface-success, #d1e6b5);
    }
  }

  .messageContainer {
    display: flex;
    padding: var(--space-none, 0rem);
    gap: var(--space-1-x, 0.9rem);
    align-self: stretch;
  }

  .request-condition {
    width: 16rem;
    height: auto;
    padding: calc($grid-1x/2) 0 $grid-1x;
    color: $primary-text-hover;
    display: flex;
    flex-direction: column;
    align-self: stretch;
    width: 80%;
  }

  #mainText {
    color: var(--color-ink-leading, #1a1a1a);
    font-family: "SoDo Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  #subText {
    color: var(--color-ink-leading, #1a1a1a);
    font-family: "SoDo Sans";
    font-size: 0.83rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4rem;
  }

  #subTextHour {
    color: var(--color-ink-leading, #1a1a1a);
    font-family: "SoDo Sans";
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  #total-hours {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    align-self: stretch;
    width: 13%;
    padding-left: 2%;
    padding-right: 2%;
  }

  #totalHourValue {
    color: var(--color-ink-leading, #1a1a1a);
    text-align: right;
    font-family: "SoDo Sans";
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 400;
    padding: var(--space-none, 0rem);
    gap: var(--space-1-x, 0.5rem);
    align-self: stretch;
    line-height: normal;
  }
}
