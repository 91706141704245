@import "../../../styles/variables.scss";
@import "../../../styles/colors.scss";
@import "../../../styles/mixins.scss";

#resultMessage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  overflow: hidden;

  @media screen and (min-width: $breakpoint-normal-desktop) {
    padding: var(--space-6-x, 3rem);
    gap: 1.25rem;
    width: 50%;
    position: fixed;
  }

  @media screen and (min-width: $breakpoint-xs-mobile),
    (min-width: $breakpoint-sm-mobile),
    (min-width: $breakpoint-mobile) {
    height: 45rem;
    margin-top: 1.25rem;
  }

  @media screen and (min-width: $breakpoint-sm-desktop) {
    margin: 1rem;
    padding: var(--space-6-x, 2.5rem);
    height: auto;
    gap: 1rem;
    width: 50%;
    position: fixed;
  }
}

.warningIcon {
  margin-bottom: var(--space-3-x, 24px);

  @media only screen and (min-width: $breakpoint-xs-mobile),
    (min-width: $breakpoint-mobile),
    (min-width: $breakpoint-sm-mobile) {
    width: 32px;
    height: 32px;
  }

  @media only screen and (min-width: $breakpoint-normal-desktop),
    (min-width: $breakpoint-sm-desktop) {
    width: 56px;
    height: 56px;
  }
}

#title {
  color: var(--Ink-leading, #1e1e1e);
  text-align: center;
  line-height: normal;
  align-self: stretch;
  margin-bottom: var(--space-3-x, 24px);
  font-size: 28px;
  font-style: normal;
  font-family: $SodoSansSemiBold;

  @media only screen and (min-width: $breakpoint-xs-mobile),
    (min-width: $breakpoint-mobile),
    (min-width: $breakpoint-sm-mobile) {
    font-size: 18px;
  }

  @media only screen and (min-width: $breakpoint-normal-desktop),
    (min-width: $breakpoint-sm-desktop) {
    font-size: 28px;
  }
}

#subtitle {
  color: var(--color-ink-supporting, #666);
  text-align: center;
  width: 100%;
  font-style: normal;
  font-family: $SodoSans;

  @media only screen and (min-width: $breakpoint-normal-desktop),
    (min-width: $breakpoint-sm-desktop) {
    font-size: 18px;
  }

  @media only screen and (min-width: $breakpoint-sm-mobile),
    (min-width: $breakpoint-xs-mobile),
    (min-width: $breakpoint-mobile) {
    font-size: 14px;
  }
}

#button {
  display: flex;
  height: 48px;
  padding: 0px var(--space-6-x, 48px);
  justify-content: center;
  align-items: center;
  transform: none;
  padding: 0px var(--space-6-x, 48px);
  justify-content: center;
  align-items: center;
  border-radius: var(--radius-radius-minimal, 8px);
  background: var(--color-interactive-primary, #00754a);
  background-color: $primary;
  @include roundCorner($grid-2x);
  color: $secondary-text;
  font-size: 1.143rem;
  font-family: $SodoSansSemiBold;
  padding: calc($grid-1x + 4px) $grid-2x;
  text-transform: none;
  min-width: calc(3 * $grid-9x);

  .MuiButton-startIcon {
    .MuiSvgIcon-root {
      font-size: 1.9rem;
    }
  }

  &:hover {
    background-color: $primary-hover;
  }

  &:focus {
    background-color: $secondary-focus;
  }

  &.Mui-disabled {
    background-color: #666;
    color: #fff;
  }
}

.button-block {
  display: flex;
  height: 48px;
  padding: 0px var(--space-6-x, 48px);
  justify-content: center;
  align-items: center;
  position: relative;

  @media only screen and (min-width: $breakpoint-xs-mobile),
    (min-width: $breakpoint-mobile),
    (min-width: $breakpoint-sm-mobile) {
    margin-top: 12.5rem;
  }

  @media only screen and (min-width: $breakpoint-normal-desktop),
    (min-width: $breakpoint-sm-desktop) {
    margin-top: 11rem;
  }
}

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.progress {
  margin-right: 0.5rem;
}
