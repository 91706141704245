/*-------| Main |--------*/
.parallel-grid {
  & > div.first-grid {
    padding: 0 calc($grid-3x + 4px);
  }
  & > div.last-grid {
    padding: 0 calc($grid-3x + 4px) $grid-2x;
  }

  &.availability-grid {
    .last-grid {
      background-color: $white500;
      @include roundCorner($grid-2x);
      height: 100%;
    }
  }

  .blank-grid {
    &.preferred-current-grid {
      margin-top: calc($grid-2x + 4px);
    }
    &.preferred-future-grid {
      margin-top: calc($grid-2x + 4px);
      padding: calc($grid-1x * 9.375) 0;
    }
  }
}

/*-------| Accordion |--------*/
.acc-wrapper {
  &.acc-requests {
    .label-wrapper {
      .label {
        width: 4rem;
      }
    }
  }
}

/*-------| Request Title |--------*/
.MuiTypography-root {
  &.card-header-title {
    &.request-title {
      padding: calc($grid-3x + 2px) 0 calc($grid-1x + 2px);
    }
  }
}

/*-------| Emp ID Modal | -----*/
.temp-modal {
  .inner-div {
    width: calc(5 * $grid-10x);
    padding: $grid-3x;
  }
}

/*-------| Button Classes |--------*/
.MuiButtonBase-root {
  &.MuiButton-root {
    &.primary-btn {
      width: 100%;
      position: static;
      transform: none;
    }
    &.Mui-disabled {
      background-color: #666;
      color: #fff;
    }
  }
}

.vertical-dotted-lines {
  margin-top: calc($grid-1x - 22px);
  width: 89%;
  margin-left: 5%;
}
