@import "../../../styles/variables";
@import "../../../styles/colors";
@import "../../../styles/mixins";

.errorPage {
  text-align: center;
  padding: $grid-2x 0 0;
  position: relative;
  top: 25vh;

  svg {
    fill: #d53f28;
  }
}
