@import "../../../styles/variables";
@import "../../../styles/colors";
@import "../../../styles/mixins";

header {
  background-color: $primary;
  @include roundCorner(0 0 $grid-3x $grid-3x);
  padding: $grid-3x calc($grid-1x + 4px) $grid-1x;

  .MuiButtonBase-root.MuiButton-root.user-info {
    padding: calc($grid-1x/2);

    &:focus {
      background-color: transparent;
    }

    h1,
    h2 {
      font-family: $SodoSansSemiBold;
      color: $secondary-text;
      text-transform: none;
    }

    .MuiSvgIcon-root {
      background-color: $white;
      @include roundCorner(50%);
      padding: calc($grid-1x - 3px);
      color: $secondary;
      font-size: 1.5rem;

      &:hover {
        color: $secondary-hover;
      }

      &:focus {
        color: $secondary-focus;
      }
    }
  }

  .header-message {
    padding: calc($grid-2x - 4px) 0 $grid-1x;

    h1,
    h2 {
      font-family: $SodoSans;
      color: $secondary-text;
    }
  }
}

@media only screen and (min-width: $breakpoint-sm-desktop) {
  header {
    padding-bottom: $grid-2x;
    .logo {
      width: calc($grid-4x - 1px);
      @include roundCorner(50%);

      .logo-img {
        width: 100%;
      }
    }

    .header-message {
      margin-left: $grid-2x;
      padding: calc($grid-1x/2) 0 $grid-1x 0;
    }
  }
}

@media only screen and (min-width: $breakpoint-normal-desktop) {
  header {
    padding: $grid-3x;

    .logo {
      width: calc($grid-7x + 2px);
    }

    .header-message {
      margin-left: $grid-2x;

      h1,
      h2 {
        font-size: 1.33rem;
        padding: $grid-1x 0 0 $grid-2x;
      }
    }

    .MuiButtonBase-root.MuiButton-root.user-info {
      h1,
      h2 {
        font-size: 1.21rem;
      }

      .MuiSvgIcon-root {
        padding: calc($grid-1x + 2px);
        margin-left: $grid-1x;
      }
    }
  }
}

@media only screen and (min-width: $breakpoint-normal-desktop2) {
  header {
    .header-message {
      h1,
      h2 {
        font-size: 1.4rem;
      }
    }

    .user-info {
      h4 {
        font-size: 1.333rem;
      }
    }
  }
}
