@import "../../../styles/variables";
@import "../../../styles/colors";
@import "../../../styles/mixins";

.statement {
  .title {
    svg {
      font-size: 1.471rem;
      margin-right: calc($grid-1x/2);
      fill: $primary-text;
    }

    span.MuiTypography-root {
      color: $primary-text;
      font-size: 1.071rem;
    }
  }

  .message {
    font-family: $SodoSans;
    color: $primary-text-hover;
    padding: calc($grid-1x/4) 0 0 calc($grid-3x - 2px);
  }

  &.success {
    .title {
      svg {
        fill: $darkgreen;
      }

      span.MuiTypography-root {
        color: $darkgreen;
      }
    }
  }

  &.error {
    .title {
      svg {
        fill: $red100;
      }

      span.MuiTypography-root {
        color: $red100;
      }
    }
  }

  &.error {
    .title {
      img {
        width: calc($grid-2x + 1px);
      }

      span.MuiTypography-root {
        color: $error-text;
        margin: 0px 0px 0px calc($grid-1x - 3px);
      }
    }
  }
}

@media only screen and (min-width: $breakpoint-mobile) {
  .statement {
    &.error {
      .title {
        img {
          width: calc($grid-2x + 4px);
        }
      }

      .message {
        padding: calc($grid-1x/4) 0 0 calc($grid-3x + 1px);
      }
    }
  }
}

@media only screen and (min-width: $breakpoint-sm-desktop) {
  .statement {
    &.error {
      .title {
        img {
          width: calc($grid-3x + 2px);
        }
      }

      .message {
        padding: calc($grid-1x/4) 0 0 calc($grid-4x);
      }
    }
  }
}

@media only screen and (min-width: $breakpoint-normal-desktop) {
  .statement {
    .title {
      svg {
        width: calc($grid-3x - 1px);
        height: calc($grid-3x - 1px);
      }
    }

    .message {
      padding: calc($grid-1x/4) 0 0 calc($grid-4x);
    }

    &.error {
      .title {
        img {
          width: calc($grid-3x + 2px);
        }
      }
    }
  }
}
