@import "../../../../../styles/variables";
@import "../../../../../styles/colors";
@import "../../../../../styles/mixins";

.acc-wrapper.cancelled-requests {
  &.acc-requests {
    .label-wrapper {
      .label {
        svg {
          width: 1.35rem;
          fill: $primary-text;
          padding: 0px 2px 0px 0px;
        }
      }
    }
  }
}
