@media only screen and (min-width: $breakpoint-sm-mobile) {
  @import "390";
}

@media only screen and (min-width: $breakpoint-mobile) {
  @import "480";
}

@media only screen and (min-width: $breakpoint-tablet) {
  @import "768";
}

@media only screen and (min-width: $breakpoint-sm-desktop) {
  @import "900";
}

@media only screen and (min-width: $breakpoint-normal-desktop) {
  @import "1200";
}

@media only screen and (min-width: $breakpoint-normal-desktop2) {
  /*-------| Main |--------*/
  main {
    width: $containerNormal;
    padding: calc($grid-4x - 2px) 0 0;
  }
}

@media only screen and (min-width: $breakpoint-large-desktop) {
  @import "1536";
}
