@import "../../../styles/variables";
@import "../../../styles/colors";
@import "../../../styles/mixins";

.noData-container {
  background-color: $white500;
  padding: $grid-1x;
  @include roundCorner($grid-2x);
  color: $primary-text-hover;

  div {
    margin: calc($grid-1x + calc($grid-1x/2)) $grid-1x;
    img {
      display: none;
      width: $grid-6x;
    }
  }
}
