/*-------| Emp ID Modal | -----*/
.temp-modal {
  .inner-div {
    padding: $grid-3x;
  }
}

.vertical-dotted-lines {
  margin-top: calc($grid-1x - 22px);
  width: 93%;
  margin-left: 3%;
}
