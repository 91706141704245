@import "../../../styles/variables";
@import "../../../styles/colors";
@import "../../../styles/mixins";

.confirm-dialog {
  .MuiPaper-root {
    background-color: $white;
    @include roundCorner($grid-2x);
    padding: $grid-1x;

    .dialog-header {
      h2 {
        font-size: 1.286rem;
        font-family: $SodoSansSemiBold;
        color: $primary-text;
        padding: calc($grid-1x - 2px) calc($grid-1x);
      }

      button {
        color: $primary-text;
      }
    }

    .dialog-content {
      padding: calc($grid-1x - 2px) calc($grid-1x);
    }

    .MuiDialogActions-root {
      flex-direction: column-reverse;
      padding: $grid-2x $grid-1x calc($grid-1x/2);

      button {
        width: 100%;
        font-size: 1.1rem;
        margin: 0 0 calc($grid-1x - 4px);
        padding: $grid-1x 0;
        @include roundCorner($grid-2x);
      }
    }
  }
}
