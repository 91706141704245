@import "../../../styles/colors";
@import "../../../styles/variables.scss";

.page-container {
  height: 100vh;

  .access-denied-content {
    padding: 0 $grid-3x;
    max-width: calc(64 * $grid-1x);
    svg {
      font-size: 2rem;
      fill: #d53f28;
      margin: -2px 0 0 -1px;
    }
    .MuiStack-root {
      width: calc(100% - $grid-3x - 4px);

      span {
        color: $grey300;
      }
    }
  }
}

@media only screen and (min-width: $breakpoint-mobile) {
  .page-container {
    .access-denied-content {
      svg {
        fill: #d53f28;
        font-size: 2.1rem;
        margin: -3px 0px 0px 3px;
      }
      .MuiStack-root {
        width: calc(100% - $grid-5x);
      }
    }
  }
}

@media only screen and (min-width: $breakpoint-sm-desktop) {
  .page-container {
    .access-denied-content {
      svg {
        fill: #d53f28;
        font-size: 2.1rem;
        margin: -4px 0px 0px 4px;
      }
      .MuiStack-root {
        width: calc(100% - $grid-5x);
      }
    }
  }
}

@media only screen and (min-width: $breakpoint-normal-desktop) {
  .page-container {
    .access-denied-content {
      svg {
        fill: #d53f28;
        font-size: 2.1rem;
        margin: -3px 0px 0px -11px;
      }
      .MuiStack-root {
        width: calc(100% - $grid-5x);
      }
    }
  }
}
