@import "variables";
@import "colors";
@import "mixins";

* {
  margin: 0;
  padding: 0;
}

/*--------| HTML, Body |---------*/
html {
  font-size: 12px;
  line-height: 1.33;
  font-family: $SodoSans;
  color: $primary-text;
  background-color: $secondary-text;
  -webkit-font-smoothing: antialiased;
}

.fullHeight {
  height: 100vh;
}

/*--------| Alignment |---------*/
.left {
  float: left;
}

.right {
  float: right;
}

/*--------| Text Decoration |---------*/
.underline {
  text-decoration: underline;
}

/*--------| Headings |---------*/
h1.MuiTypography-root {
  font-size: 1.286rem;
  line-height: 1.27;
}

h2.MuiTypography-root {
  font-size: 1.143rem;
  line-height: 1.3125;
}

h3.MuiTypography-root {
  font-size: 1.071rem;
  line-height: 1.266;
}

h4.MuiTypography-root {
  font-size: 1.21rem;
}

h5.MuiTypography-root {
  font-size: 1.1rem;
}

.MuiTypography-body2 {
  font-size: 0.9rem;
}

/*--------| Spacing |---------*/
.p-1 {
  padding: $grid-1x;
}

.p-2 {
  padding: $grid-2x;
}

.p-3 {
  padding: $grid-3x;
}

.p-4 {
  padding: $grid-4x;
}

.pt-1 {
  padding-top: $grid-1x;
}

.pt-2 {
  padding-top: $grid-2x;
}

.pt-3 {
  padding-top: $grid-3x;
}

.pt-4 {
  padding-top: $grid-4x;
}

.pb-1 {
  padding-bottom: $grid-1x;
}

.pb-2 {
  padding-bottom: $grid-2x;
}

.pb-3 {
  padding-bottom: $grid-3x;
}

.pb-4 {
  padding-bottom: $grid-4x;
}

.pl-1 {
  padding-left: $grid-1x;
}

.pl-2 {
  padding-left: $grid-2x;
}

.pl-3 {
  padding-left: $grid-3x;
}

.pl-4 {
  padding-left: $grid-4x;
}

.pr-1 {
  padding-right: $grid-1x;
}

.pr-2 {
  padding-right: $grid-2x;
}

.pr-3 {
  padding-right: $grid-3x;
}

.pr-4 {
  padding-right: $grid-4x;
}

.mt-1 {
  margin-top: $grid-1x;
}

.mt-2 {
  margin-top: $grid-2x;
}

.mt-3 {
  margin-top: $grid-3x;
}

.mt-4 {
  margin-top: $grid-4x;
}

.mb-1 {
  margin-bottom: $grid-1x;
}

.mb-2 {
  margin-bottom: $grid-2x;
}

.mb-3 {
  margin-bottom: $grid-3x;
}

.mb-4 {
  margin-bottom: $grid-4x;
}

.ml-1 {
  margin-left: $grid-1x;
}

.ml-2 {
  margin-left: $grid-2x;
}

.ml-3 {
  margin-left: $grid-3x;
}

.ml-4 {
  margin-left: $grid-4x;
}

.mr-1 {
  margin-right: $grid-1x;
}

.mr-2 {
  margin-right: $grid-2x;
}

.mr-3 {
  margin-right: $grid-3x;
}

.mr-4 {
  margin-right: $grid-4x;
}

/*-------| Color Classes |--------*/
.white {
  color: $white;
}

.black {
  color: $black;
}

.black100 {
  color: $black100;
}

.black200 {
  color: $black200;
}

.black500 {
  color: $black500;
}

.green {
  color: $green;
}

.primary-text {
  color: $primary-text;
}

.primary-text-hover {
  color: $primary-text-hover;
}

/*-------| Fonts Classes |--------*/
.MuiTypography-root {
  &.light-font {
    font-family: $SodoSansLight;
  }

  &.regular-font {
    font-family: $SodoSans;
  }

  &.semi-bold-font {
    font-family: $SodoSansSemiBold;
  }

  &.bold-font {
    font-family: $SodoSansBold;
  }
}

/*-------| Button Classes |--------*/
.MuiButtonBase-root {
  &.MuiButton-root {
    @include roundCorner($grid-1x);
    color: $primary-text;
    font-size: 1rem;
    align-items: center;
    padding: calc($grid-1x/2) $grid-2x 3px;
    font-family: $SodoSans;
    text-transform: uppercase;

    &:hover {
      color: $primary-text-hover;
    }

    .MuiButton-startIcon {
      margin-right: calc($grid-1x/4);

      .MuiSvgIcon-root {
        font-size: 1.2rem;
      }
    }

    &.primary-btn {
      background-color: $primary;
      @include roundCorner($grid-2x);
      color: $secondary-text;
      font-size: 1.143rem;
      font-family: $SodoSansSemiBold;
      padding: calc($grid-1x + 4px) $grid-2x;
      text-transform: none;
      min-width: calc(3 * $grid-9x);
      position: fixed;
      bottom: $grid-1x;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;
      margin-top: $grid-5x;

      .MuiButton-startIcon {
        .MuiSvgIcon-root {
          font-size: 1.9rem;
        }
      }

      &:hover {
        background-color: $primary-hover;
      }

      &:focus {
        background-color: $secondary-focus;
      }
    }

    &.button-cancel-btn {
      display: flex;
      height: 1.8rem;
      padding: 0rem 0.5rem;
      justify-content: center;
      align-items: center;
      border-radius: var(--radius-minimal, 0.5rem);
      border: 0.5px solid var(--color-line-quiet, #bfbfbf);
      background: var(--color-interactive-tertiary, #fff);
      color: var(--color-ink-on-tertiary, #006241);
      text-transform: none;
      font-family: "SoDo Sans";
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.25rem;
    }
    &.button-cancel-btn-mobile {
      display: flex;
      height: 2rem;
      padding: 0rem 0.5rem;
      justify-content: center;
      align-items: center;
      border-radius: var(--radius-minimal, 0.5rem);
      border: 0.5px solid var(--color-line-quiet, #bfbfbf);
      background: var(--color-interactive-tertiary, #fff);
      color: var(--color-ink-on-tertiary, #006241);
      text-transform: none;
      font-family: "SoDo Sans";
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.25rem;
    }

    &.cancel-btn-create {
      min-width: 8rem;
      min-height: 1.8rem;
      margin-left: 90%;
      display: flex;
      padding: 0rem var(--space-3-x, 1.5rem);
      justify-content: center;
      align-items: center;
      border-radius: var(--radius-minimal, 0.5rem);
      border: 0.5px solid var(--color-line-quiet, #bfbfbf);
      background: var(--color-interactive-tertiary, #fff);
      text-transform: none;

      color: var(--color-ink-on-tertiary, #006241);
      font-family: "SoDo Sans";
      font-size: 0.8125rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.25rem;
    }

    &.next-btn-create {
      min-width: 8rem;
      min-height: 1.8rem;
      display: flex;
      padding: 0rem var(--space-3-x, 1.5rem);
      justify-content: center;
      align-items: center;
      border-radius: var(--radius-minimal, 0.5rem);
      background: var(--color-interactive-primary, #00754a);
      color: var(--color-ink-on-primary, #fff);
      font-family: "SoDo Sans";
      font-size: 0.8125rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.25rem;
      &.disabled {
        border-radius: var(--radius-minimal, 0.5rem);
        background: var(--color-interactive-primary-disabled, #666);
      }

      text-transform: none;
      width: 6.125rem;
    }
    &.next-btn-create-mobile {
      min-width: 8rem;
      height: 2rem;
      display: flex;
      padding: 0rem var(--space-3-x, 1.5rem);
      justify-content: center;
      align-items: center;
      border-radius: var(--radius-minimal, 0.5rem);
      background: var(--color-interactive-primary, #00754a);
      color: var(--color-ink-on-primary, #fff);
      font-family: "SoDo Sans";
      font-size: 0.8125rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.25rem;
      &.disabled {
        border-radius: var(--radius-minimal, 0.5rem);
        background: var(--color-interactive-primary-disabled, #666);
      }

      text-transform: none;
      width: 6.125rem;
    }

    &.create-submit-btn {
      width: 6.125rem;
      display: flex;
      height: 1.8rem;
      padding: 0rem 0.5rem;
      justify-content: center;
      align-items: center;
      border-radius: var(--radius-minimal, 0.5rem);
      background: var(--color-interactive-primary, #00754a);
      color: var(--color-ink-on-primary, #fff);
      font-family: "SoDo Sans";
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.25rem;
      text-transform: none;

      &[disabled] {
        background: var(--color-interactive-primary-disabled, #666);
        color: var(--color-ink-on-primary-disabled, #fff);
      }
    }
    &.create-submit-btn-mobile {
      width: 6.125rem;
      display: flex;
      height: 2rem;
      padding: 0rem 0.5rem;
      justify-content: center;
      align-items: center;
      border-radius: var(--radius-minimal, 0.5rem);
      background: var(--color-interactive-primary, #00754a);
      color: var(--color-ink-on-primary, #fff);
      font-family: "SoDo Sans";
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.25rem;
      text-transform: none;

      &[disabled] {
        background: var(--color-interactive-primary-disabled, #666);
        color: var(--color-ink-on-primary-disabled, #fff);
      }
    }

    &.secondary-btn {
      display: flex;
      padding: 0px var(--space-3-x, 24px);
      justify-content: center;
      align-items: center;
      border-radius: var(--radius-minimal, 8px);
      background: var(--color-interactive-primary, #00754a);
      background-color: $secondary;
      color: $secondary-text;
      font-family: $SodoSansSemiBold;
      text-transform: none;

      &.next-btn {
        @include roundCorner($grid-2x);
      }

      &:hover {
        background-color: $secondary-hover;
      }

      &:focus {
        background-color: $secondary-focus;
      }
    }

    &.cancel-btn {
      background-color: $green800;
      @include roundCorner($grid-4x);
      color: $primary-text;
      font-family: $SodoSansSemiBold;
      text-transform: none;

      &:hover {
        background-color: $green700;
      }

      &:focus {
        background-color: $green600;
      }
    }

    &.close-btn {
      @include roundCorner($grid-4x);
      color: $primary-text;
      font-family: $SodoSansSemiBold;
      text-transform: none;
      text-decoration: underline;
    }

    &.success-btn {
      background-color: $success;
      color: $success-text;

      &:hover {
        background-color: $success-hover;
      }
    }

    &.warning-btn {
      background-color: $warning;
      color: $warning-text;

      &:hover {
        background-color: $warning-hover;
      }
    }

    &.error-btn {
      background-color: $error;
      color: $error-text;

      &:hover {
        background-color: $error-hover;
      }
    }

    &.Mui-disabled {
      background-color: #666;
      color: #fff;
    }
  }
}

/*-------| Status Label |--------*/
.label {
  display: flex;
  height: 1.25rem;
  padding: 0.125rem var(--space-half-x, 0.25rem);
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  width: 4rem;

  svg {
    margin-right: calc($grid-1x/4);
  }

  .MuiTypography-body1 {
    font-family: "SoDo Sans";
    font-size: 0.85rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
  }

  &.cancel {
    background-color: $grey500;
    border-radius: 0.25rem;
    border: 0.5px solid var(--color-line-on-caution, #000000);
    background: var(--color-surface-caution, $grey500);
  }

  &.expired {
    background-color: #e7e7e7;
    border-radius: 0.25rem;
    border: 0.5px solid var(--color-line-quiet, #bfbfbf);
    background: var(--Surface-level-2, #e7e7e7);
  }

  &.success {
    border-radius: 0.25rem;
    border: 0.5px solid var(--color-line-on-success, #4b833d);
    background: var(--color-surface-success, #d1e6b5);
    color: #1b4a22;
  }

  &.warning {
    background-color: #f4e2ac;
    color: #645123;
    white-space: nowrap;
    border-radius: 0.25rem;
    border: 0.5px solid var(--color-line-on-caution, #98793d);
    background: var(--color-surface-caution, #f4e2ac);
  }

  &.error {
    background-color: #fdded2;
    color: #9a2a21;
    border-radius: 0.25rem;
    border: 0.5px solid var(--color-line-on-error, #d53f28);
    background: var(--color-surface-error, #fdded2);
  }
}

/*-------| User Tabs |--------*/
.MuiTabs-root {
  &.user-tabs {
    margin-top: 0.5rem;
    min-height: auto;
    padding: 0 calc($grid-1x + 4px) calc($grid-2x - 2px);

    .MuiTab-root {
      min-height: auto;
      padding: calc($grid-1x - 3px) 0;
      text-transform: none;
      font-size: 1.143rem;
      color: $primary-text-hover;
      font-family: $SodoSansSemiBold;
      margin: 0.2rem;
      margin-right: $grid-3x;

      &.Mui-selected {
        color: $secondary-focus;
      }

      &.MuiTab-root:focus-visible {
        outline: 2px solid #00754a;
        background-color: transparent;
      }
    }

    .MuiTabs-indicator {
      background-color: $secondary-focus;
    }
  }
}
