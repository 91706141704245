@import "../../../styles/variables";
@import "../../../styles/colors";
@import "../../../styles/mixins";

.error-page {
  background-color: $red600;
  width: 100vw;
  height: 100vh;
  text-align: center;

  & > div {
    background-color: rgba($red500, 0.6);
    padding: $grid-3x;
    @include roundCorner($grid-2x);
  }

  h1,
  h4 {
    color: $black;
    padding: $grid-1x 0;
  }
}
